<template>
  <div>
    <b-form-select v-model="selectedSeason" :options="seasonsList"></b-form-select>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selectedSeason",
  },
  props:{
    selected: { type: Number, default: null},
  },
  data() {
    return {
      selectedSeason: this.selected,
    }
  },
  method:{
    seasonsList(){
      return [
        { value: null, text: "Choisissez une saison"},
        { value: 2020, text: "2020"},
        { value: 2021, text: "2021"},
        { value: 2022, text: "2022"},
        { value: 2023, text: "2023"}
      ]
    }
  }
}
</script>
